<template>
  <page-main
    title="Organizations & Certifications"
    nextText="Education"
    buttonTabIndex="4"
    eventName="nav"
    @nav="nav('/Education')"
    @save="save()"
  >
    <template v-slot:aside>
      Being involved in organizations (professional or volunteer) shows your
      involvement in your profession and community. List organizations you
      actively belong to or belonged to in the past.
    </template>
    <template v-slot:nav>
      <v-system-bar color="accent" height="5" class="mt-4"></v-system-bar>
      <modal cardTitle="Example" ctaText="Need an example?">
        <ul class="font-italic">
          <li class="mb-4">
            <span class="font-weight-bold">Example #1:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >Professional organizations you belong to:</span
                >
                American Society of Mechanical Engineers (ASME) (2013-present)
              </li>
              <li>
                <span class="font-weight-bold"
                  >Volunteer organizations you belong to:</span
                >
                Rotaract (2015-2018)
              </li>
              <li>
                <span class="font-weight-bold">Licenses & Certifications:</span>
                Six Sigma Greenbelt (2014)
              </li>
            </ul>
          </li>
          <li class="mb-4">
            <span class="font-weight-bold">Example #2:</span>
            <ul>
              <li>
                <span class="font-weight-bold"
                  >Professional Organizations you are a member of:</span
                >
                American Society of Mechanical Engineers (ASME) (2013-present)
              </li>
              <li>
                <span class="font-weight-bold"
                  >Volunteer Organizations you are part of:</span
                >
                Rotaract (2015-2018)
              </li>
              <li>
                <span class="font-weight-bold">Licenses & Certifications:</span>
                Fundamentals of Engineering (2014-2019)
              </li>
            </ul>
          </li>
        </ul>
      </modal>
      <modal cardTitle="Tips" ctaText="Need a few tips?" :accentButton="true">
        <r-tip-container :tips="tips"></r-tip-container>
      </modal>
    </template>
    <v-container>
      <v-row>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Add professional organizations here one at a time"
            titleText="Professional organizations you are a member of:"
            :optionsList="[]"
            :selected.sync="professionalOrg"
            tabindex="1"
            autofocus
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Add volunteer organizations here one at a time"
            titleText="Volunteer organizations you are a part of:"
            :optionsList="[]"
            :selected.sync="volunteerOrg"
            tabindex="2"
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
        <v-col cols="12" class="r-col">
          <r-multi-select
            label="Add licenses & certifications here one at a time"
            titleText="Licenses & Certifications:"
            :optionsList="[]"
            :selected.sync="licensesCerts"
            tabindex="3"
            :maxSelections="25"
          ></r-multi-select>
        </v-col>
      </v-row>
    </v-container>
  </page-main>
</template>

<script>
import * as Display from "../utility/display";
import { mapFields } from "vuex-map-fields";
import { mapActions } from "vuex";
import Modal from "@/components/Modal.vue";

// @ is an alias to /src
export default {
  name: "Organizations",
  data: () => ({}),
  components: { Modal },
  methods: {
    save() {
      this.buildDisplay();
      this.saveOrganizations();
      this.saveLicenseCert();
    },
    ...mapActions("organization", ["saveOrganizations"]),
    ...mapActions("licenseCert", ["saveLicenseCert"]),
    nav(routerPath) {
      this.$router.push({ path: routerPath });
    },
    buildDisplay() {
      let professionalOrgs = Display.BuildCommaString("", this.professionalOrg);
      let volunteerOrgs = Display.BuildCommaString("", this.volunteerOrg);
      let licensesDisplay = Display.BuildCommaString("", this.licensesCerts);

      this.professionalOrgDisplay = professionalOrgs;
      this.volunteerOrgDisplay = volunteerOrgs;
      this.licensesCertsDisplay = licensesDisplay;

      return [professionalOrgs, volunteerOrgs, licensesDisplay];
    },
  },
  mounted() {},
  computed: {
    displayValues: function () {
      return this.buildDisplay();
    },
    ...mapFields("organization", [
      "professionalOrg",
      "professionalOrgDisplay",
      "volunteerOrg",
      "volunteerOrgDisplay",
    ]),
    ...mapFields("licenseCert", ["licensesCerts", "licensesCertsDisplay"]),
    tips() {
      return [
        "Where applicable, we recommend listing the year(s) you were active in parenthesis after the name of the organization. If you have a license or certification, we recommend you put the year you earned it and the year it expires if applicable.",
      ];
    },
  },
};
</script>
